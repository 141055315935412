<template>
  <div class="select-container">
    <div
      v-if="selected && !show"
      class="select"
      role="button"
      tabindex="0"
      @click="select"
      @keypress.space="select"
    >
      {{ selected }}
      <IconBase
        icon-name="chevron-down"
        icon-color="#7360A4"
        class="svg"
        width="24"
        height="24"
      />
    </div>
    <div
      v-if="!selected || show"
      class="option"
    >
      <div class="icon">
        <IconButton
          icon-name="close-line"
          purpose="transparent"
          icon-color="var(--colour-utility-white)"
          :icon-size="18"
          small
          @click="close"
        />
      </div>
      <div
        v-for="(item, index) in items"
        :key="index"
        class="value"
        :class="`${item.value === selectedValue ? 'active' : ''}`"
        role="button"
        tabindex="0"
        @click="onChange(item)"
        @keypress.space="onChange(item)"
      >
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { IconButton, IconBase } from '@sales-i/dsv3';
import { tHeader as t } from '@sales-i/utils';

export default {
  components: {
    IconBase,
    IconButton,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedValue: {
      type: String,
      default: '',
    },
  },
  emits: ['close', 'onChange'],
  data() {
    return {
      show: false,
      text: [
        {
          text: t('customers'),
          value: 'customer',
        },
        {
          text: t('opportunities'),
          value: 'opportunities',
        },
        {
          text: t('contacts'),
          value: 'contacts',
        },
        {
          text: t('files'),
          value: 'files',
        },
      ],
    };
  },
  computed: {
    selected() {
      return this.items.find(e => e.value === this.selectedValue) ? this.items.find(e => e.value === this.selectedValue).text : '';
    },
  },
  methods: {
    onChange(item) {
      this.show = false;
      this.$emit('onChange', item);
    },
    select() {
      this.show = true;
    },
    close() {
      this.show = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.select-container {
  display: block;
  white-space: nowrap;
  .select {
    display: flex;
    cursor: pointer;
    padding: var(--spacing-1);
    padding-left: var(--spacing-2);
    padding-left: var(--spacing-2);
    font-size: 12px;
    align-items: center;
    background-color: var(--colour-panel-action);
    min-width: 120px;
    .svg {
      margin-left: auto;
    }
  }
  .option {
    position: absolute;
    color: white;
    top: 20px;
    right: 25px;
    padding: var(--spacing-1) 0px;
    background-color: var(--colour-utility-black);
    border-radius: var(--border-radius-1);
    z-index: 2;
    .value {
      padding-top: var(--spacing-1);
      padding-bottom: var(--spacing-1);
      padding-left: var(--spacing-2);
      padding-right: var(--spacing-2);
      cursor: pointer;
      &.active {
        background-color: #282828;
      }
    }
    .icon {
      display: flex;
      justify-content: right;
    }
  }
}
</style>
