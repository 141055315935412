// report types
export const HIGHEST_SELLING_PRODUCTS = 'highest-selling-products';
export const LOWEST_SELLING_PRODUCTS = 'lowest-selling-products';
export const HIGHEST_SELLING_PRODUCT_GROUP = 'highest-selling-product-group';
export const LOWEST_SELLING_PRODUCT_GROUP = 'lowest-selling-product-group';
export const HIGHEST_MARGIN_PRODUCT = 'highest-margin-product';
export const LOWEST_MARGIN_PRODUCT = 'lowest-margin-product';
export const HIGHEST_MARGIN_PRODUCT_GROUP = 'highest-margin-product-group';
export const LOWEST_MARGIN_PRODUCT_GROUP = 'lowest-margin-product-group';
export const HIGHEST_MARGIN_CUSTOMER = 'highest-margin-customer';
export const LOWEST_MARGIN_CUSTOMER = 'lowest-margin-customer';
export const HIGHEST_REVENUE_CUSTOMER = 'highest-revenue-customer';
export const LOWEST_REVENUE_CUSTOMER = 'lowest-revenue-customer';
export const CUSTOMER_DOWN_ON_SPEND = 'customer-down-on-spend';
export const CUSTOMER_WITH_ZERO_SPEND = 'customer-with-zero-spend';
export const CUSTOMER_TOP_NUMBER_OF_ACCOUNTS_DOWN = 'customer-top-number-of-accounts-down';
export const CUSTOMER_WHO_SPENT_IN_PREVIOUS = 'customer-who-spent-in-previous';
export const CUSTOMER_DORMANT_YTD = 'customer-dormant-ytd';
export const CUSTOMER_LAST_COMPLETE_PERIODS_BUT_NOT_BOUGHT = 'customer-last-complete-periods-but-not-bought';
export const CUSTOMER_NOT_BUYING_TOP_PRODUCTS = 'customer-not-buying-top-products';
export const CUSTOMER_DECREASING_SPEND_PERIOD = 'customer-decreasing-spend-period';
export const CUSTOMER_SNAPSHOT = 'customer-snapshot';
export const PERFORMANCE_SNAPSHOT = 'performance-snapshot';
export const BRANCH_SNAPSHOT = 'branch-snapshot';
export const CUSTOMER_ACTIVITY = 'customer-activity';

export const SALES_REP_LEADERBOARD = 'sales-rep-leaderboard';
export const HIGH_REVENUE_SELLERS = 'high-revenue-sellers';
export const MOST_IMPROVED = 'most-improved';
export const REP_PRODUCTIVITY = 'rep-productivity';
export const REPS_WITH_GROWTH_POTENTIAL = 'reps-with-growth-potential';
export const REPS_THAT_NEED_SUPPORT = 'reps-that-need-support';

export const FULL_PICTURE = 'full-picture';
export const MATRIX = 'matrix';
export const TRENDS = 'trends';
export const SALES_VS_GAPS = 'sales-vs-gaps';
export const VARIANCE = 'variance';
export const QUERY_BUILDER = 'query-builder';

export const AIML_PRODUCT_TRENDS = 'aiml-product-trends';
export const SALES_OPPORTUNITY = 'sales-opportunity';
export const SALES_OPPORTUNITY_DEMO = 'sales-opportunity-demo';
export const INTERACTION_OUTCOMES = 'interaction-outcomes';
export const INTERACTION_OUTCOMES_DRILL = 'interaction-outcomes-drill';
export const INTERACTION_OUTCOMES_DEMO = 'interaction-outcomes-demo';
export const PRODUCT_ASSOCIATIONS_INSIGHTS = 'product-associations-insights';
export const PRODUCT_COMMUNITY_INSIGHTS = 'product-community-insights';
export const INTERACTION_SPEND_SUMMARY = 'interaction-spend-summary';

// report titles
export const HIGHEST_SELLING_PRODUCTS_TITLE = 'Highest selling products';
export const LOWEST_SELLING_PRODUCTS_TITLE = 'Lowest selling products';
export const HIGHEST_SELLING_PRODUCT_GROUP_TITLE = 'Highest selling product group';
export const LOWEST_SELLING_PRODUCT_GROUP_TITLE = 'Lowest selling product group';
export const HIGHEST_MARGIN_PRODUCT_TITLE = 'Highest margin product';
export const LOWEST_MARGIN_PRODUCT_TITLE = 'Lowest margin product';
export const HIGHEST_MARGIN_PRODUCT_GROUP_TITLE = 'Highest margin product group';
export const LOWEST_MARGIN_PRODUCT_GROUP_TITLE = 'Lowest margin product group';
export const HIGHEST_MARGIN_CUSTOMER_TITLE = 'Highest margin customer';
export const LOWEST_MARGIN_CUSTOMER_TITLE = 'Lowest margin customer';
export const HIGHEST_REVENUE_CUSTOMER_TITLE = 'Highest revenue customer';
export const LOWEST_REVENUE_CUSTOMER_TITLE = 'Lowest revenue customer';
export const CUSTOMER_DOWN_ON_SPEND_TITLE = 'Customer down on spend';
export const CUSTOMER_WITH_ZERO_SPEND_TITLE = 'Customers with zero spend in the past months (lost accounts)';
export const CUSTOMER_TOP_NUMBER_OF_ACCOUNTS_DOWN_TITLE = 'Customers with reduced spend by percentage';
export const CUSTOMER_WHO_SPENT_IN_PREVIOUS_TITLE = 'Customers whose spend is down on last year';
export const CUSTOMER_DORMANT_YTD_TITLE = 'Dormant YTD, spending this period';
export const CUSTOMER_LAST_COMPLETE_PERIODS_BUT_NOT_BOUGHT_TITLE = 'Last 6 complete periods, but not yet bought this period';
export const CUSTOMER_NOT_BUYING_TOP_PRODUCTS_TITLE = 'Customers (or Top Accounts) that have Not Bought our most profitable products (in the last [3/6/12 period])';
export const CUSTOMER_DECREASING_SPEND_PERIOD_TITLE = 'Customer who have decreasing spend period on period';
export const CUSTOMER_SNAPSHOT_TITLE = 'Customer Snapshot';
export const PERFORMANCE_SNAPSHOT_TITLE = 'Performance Snapshot';
export const BRANCH_SNAPSHOT_TITLE = 'Branch Snapshot';
export const CUSTOMER_ACTIVITY_TITLE = 'Customers Activity';

export const SALES_REP_LEADERBOARD_TITLE = 'Sales Rep Leader board';
export const HIGH_REVENUE_SELLERS_TITLE = 'High Revenue sellers';
export const MOST_IMPROVED_TITLE = 'Most improved';
export const REP_PRODUCTIVITY_TITLE = 'Rep Productivity';
export const REPS_WITH_GROWTH_POTENTIAL_TITLE = 'Reps with growth potential';
export const REPS_THAT_NEED_SUPPORT_TITLE = 'Reps that need support';

export const FULL_PICTURE_TITLE = 'full_picture';
export const MATRIX_TITLE = 'matrix';
export const TRENDS_TITLE = 'trends';
export const SALES_VS_GAPS_TITLE = 'sales_vs_gaps';
export const VARIANCE_TITLE = 'variance';
export const QUERY_BUILDER_TITLE = 'query_builder';

export const AIML_PRODUCT_TRENDS_TITLE = 'AI/ML Report on product trends in the previous year';
export const SALES_OPPORTUNITY_TITLE = 'sales_opportunity';
export const SALES_OPPORTUNITY_DEMO_TITLE = 'sales_opportunity';
export const INTERACTION_OUTCOMES_TITLE = 'Interaction outcomes';
export const INTERACTION_OUTCOMES_DEMO_TITLE = 'Interaction outcomes';
export const PRODUCT_ASSOCIATIONS_INSIGHTS_TITLE = 'Product Associations';
export const PRODUCT_COMMUNITY_INSIGHTS_TITLE = 'Product Communities';
export const INTERACTION_SPEND_SUMMARY_TITLE = 'Interaction spend summary';

// DEMO CONTENT
export const MARKET_PENETRATION = 'market-penetration';
export const ACTIVITY_REPORT = 'activity-report';

// array of report types
export const REPORT_TYPES = [
  // DEMO CONTENT - placed at top so first in list
  MARKET_PENETRATION,
  ACTIVITY_REPORT,

  HIGHEST_SELLING_PRODUCTS,
  LOWEST_SELLING_PRODUCTS,
  HIGHEST_SELLING_PRODUCT_GROUP,
  LOWEST_SELLING_PRODUCT_GROUP,
  HIGHEST_MARGIN_PRODUCT,
  LOWEST_MARGIN_PRODUCT,
  HIGHEST_MARGIN_PRODUCT_GROUP,
  LOWEST_MARGIN_PRODUCT_GROUP,
  CUSTOMER_ACTIVITY,
  HIGHEST_MARGIN_CUSTOMER,
  LOWEST_MARGIN_CUSTOMER,
  HIGHEST_REVENUE_CUSTOMER,
  LOWEST_REVENUE_CUSTOMER,
  CUSTOMER_DOWN_ON_SPEND,
  CUSTOMER_WITH_ZERO_SPEND,
  CUSTOMER_TOP_NUMBER_OF_ACCOUNTS_DOWN,
  CUSTOMER_WHO_SPENT_IN_PREVIOUS,
  CUSTOMER_DORMANT_YTD,
  CUSTOMER_LAST_COMPLETE_PERIODS_BUT_NOT_BOUGHT,
  CUSTOMER_NOT_BUYING_TOP_PRODUCTS,
  CUSTOMER_DECREASING_SPEND_PERIOD,
  CUSTOMER_SNAPSHOT,

  SALES_REP_LEADERBOARD,
  HIGH_REVENUE_SELLERS,
  MOST_IMPROVED,
  REP_PRODUCTIVITY,
  REPS_WITH_GROWTH_POTENTIAL,
  REPS_THAT_NEED_SUPPORT,

  FULL_PICTURE,
  MATRIX,
  TRENDS,
  SALES_VS_GAPS,
  VARIANCE,
  QUERY_BUILDER,

  AIML_PRODUCT_TRENDS,
  SALES_OPPORTUNITY,
  SALES_OPPORTUNITY_DEMO,
  INTERACTION_OUTCOMES,
  INTERACTION_OUTCOMES_DEMO,
  PRODUCT_ASSOCIATIONS_INSIGHTS,
  PRODUCT_COMMUNITY_INSIGHTS,
  INTERACTION_SPEND_SUMMARY,
  PERFORMANCE_SNAPSHOT,
  BRANCH_SNAPSHOT,
];

// Snapshot report names
export const TOP_PRODUCT_GROUPS_BY_PURCHASE_VALUE = 'top-product-groups-by-purchase-value';
export const TOP_PRODUCT_GROUPS_BY_QUANTITY_PURCHASED = 'top-product-groups-by-quantity-purchased';
export const TOP_PRODUCTS_BY_PURCHASE_VALUE = 'top-products-by-purchase-value';
export const TOP_PRODUCTS_BY_PURCHASE_QUANTITY = 'top-products-by-purchase-quantity';
export const PRODUCTS_PURCHASED_FREQUENTLY = 'products-purchased-frequently';
export const PRODUCT_GROUP_PURCHASE_ANALYSIS = 'product-group-purchase-analysis';
export const PRODUCT_GROUP_PURCHASE_ANALYSIS_PIECHART = 'product-group-purchase-analysis-piechart';
export const MONTHLY_PURCHASES_BY_VALUE = 'monthly-purchases-by-value';
export const MONTHLY_PURCHASES_BY_QUANTITY = 'monthly-purchases-by-quantity';
export const LATEST_PURCHASES_BY_VALUE = 'latest-purchases-by-value';
export const LATEST_PURCHASES_BY_QUANTITY = 'latest-purchases-by-quantity';
export const PURCHASES_VS_GAPS_BY_PURCHASE_VALUE = 'purchases-vs-gaps-by-purchase-value';
export const PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY = 'purchases-vs-gaps-by-purchase-quantity';
export const VARIANCE_ENQUIRY_BY_PURCHASE_VALUE = 'variance-enquiry-by-purchase-value';
export const VARIANCE_ENQUIRY_BY_QUANTITY = 'variance-enquiry-by-quantity';
export const ACCOUNTS_ANALYSIS = 'accounts-analysis';
export const TRANSACTIONS_ANALYSIS = 'transactions-analysis';
export const SNAPSHOT_SUMMARY = 'summary';

// Snapshot report titles
export const TOP_PRODUCT_GROUPS_BY_PURCHASE_VALUE_TITLE = 'Top Product Groups by Purchase Value';
export const TOP_PRODUCT_GROUPS_BY_QUANTITY_PURCHASED_TITLE = 'Top Product Groups by Quantity Purchased';
export const TOP_PRODUCTS_BY_PURCHASE_VALUE_TITLE = 'Top Products by Purchase Value';
export const TOP_PRODUCTS_BY_PURCHASE_QUANTITY_TITLE = 'Top Products by Purchase Quantity';
export const PRODUCTS_PURCHASED_FREQUENTLY_TITLE = 'Products Purchased Frequently';
export const PRODUCT_GROUP_PURCHASE_ANALYSIS_TITLE = 'Product Group Purchase Analysis';
export const MONTHLY_PURCHASES_BY_VALUE_TITLE = 'Monthly Purchases By Value';
export const MONTHLY_PURCHASES_BY_QUANTITY_TITLE = 'Monthly Purchases By Quantity';
export const LATEST_PURCHASES_BY_VALUE_TITLE = 'Latest Purchases by Value';
export const LATEST_PURCHASES_BY_QUANTITY_TITLE = 'Latest Purchases by Quantity';
export const PURCHASES_VS_GAPS_BY_PURCHASE_VALUE_TITLE = 'Purchases vs Gaps by Purchase Value';
export const PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY_TITLE = 'Purchases vs Gaps by Purchase Quantity';
export const VARIANCE_ENQUIRY_BY_PURCHASE_VALUE_TITLE = 'Variance Enquiry by Purchase Value';
export const VARIANCE_ENQUIRY_BY_QUANTITY_TITLE = 'Variance Enquiry by Quantity';
export const ACCOUNTS_ANALYSIS_TITLE = 'Accounts Analysis';
export const TRANSACTIONS_ANALYSIS_TITLE = 'Transactions Analysis';
export const SNAPSHOT_SUMMARY_TITLE = 'Summary';

export const SNAPSHOT_REPORT_LIST = [
  SNAPSHOT_SUMMARY,
  PRODUCT_GROUP_PURCHASE_ANALYSIS,
  PRODUCT_GROUP_PURCHASE_ANALYSIS_PIECHART,
  TOP_PRODUCT_GROUPS_BY_PURCHASE_VALUE,
  TOP_PRODUCT_GROUPS_BY_QUANTITY_PURCHASED,
  TOP_PRODUCTS_BY_PURCHASE_VALUE,
  TOP_PRODUCTS_BY_PURCHASE_QUANTITY,
  PRODUCTS_PURCHASED_FREQUENTLY,
  MONTHLY_PURCHASES_BY_VALUE,
  MONTHLY_PURCHASES_BY_QUANTITY,
  LATEST_PURCHASES_BY_VALUE,
  LATEST_PURCHASES_BY_QUANTITY,
  PURCHASES_VS_GAPS_BY_PURCHASE_VALUE,
  PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY,
  VARIANCE_ENQUIRY_BY_PURCHASE_VALUE,
  VARIANCE_ENQUIRY_BY_QUANTITY,
  ACCOUNTS_ANALYSIS,
  TRANSACTIONS_ANALYSIS,
];
