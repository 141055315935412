<template>
  <div :class="`notifications-heading ${mq}`">
    <h3>
      {{ t('notifications') }}
    </h3>
    <CustomButton
      class="close-button"
      icon-name="close-line"
      icon-color="var(--colour-utility-action)"
      icon-only
      :icon-width="32"
      :icon-height="32"
      purpose="transparent"
      @on-click="closeNotifications"
    />
    <InlineTabs
      :items="navigation"
      :selected-index="selectedTabIndex"
      :selected-group-name="selectedGroupName"
      :styles="{
        fontSize: 'var(--font-size-5)',
        tabPadding: 'var(--spacing-1)',
        indent: 'var(--spacing-2)',
      }"
      @nav-clicked="navClicked"
    />
    <section>
      <NotificationItems :notifications="selectedGroup" />
    </section>
  </div>
</template>

<script>
import { tHeader as t } from '@sales-i/utils';
import { CustomButton } from '@sales-i/dsv3';
import InlineTabs from '@/shared/components/Tabs/InlineTabs.vue';
import NotificationItems from './NotificationItems.vue';
import { mapActions } from 'vuex';
import { SET_VISIBLE, CLEAR_VISIBLE } from '@/shared/store/actionType';

export default {
  name: 'NotificationBackdrop',
  components: {
    CustomButton,
    InlineTabs,
    NotificationItems,
  },
  inject: ['mq'],
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    notifications: {
      type: Array,
      default: () => [],
    },
    mentions: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    selectedFilter: {
      type: String,
      default: '',
    },
  },
  emits: ['filterSelected', 'keyup', 'search', 'closeNotifications'],
  data() {
    return {
      show: false,
      navigation: [
        {
          title: t('all'),
          key: 'ALL',
        },
        {
          title: t('mentions'),
          key: 'MENTION',
        },
        {
          title: t('activity'),
          key: 'ACTIVITY',
        },
        {
          title: t('shared_enquiries'),
          key: 'SharedEnquiry',
        },
        {
          title: t('exports'),
          key: 'EXPORT',
        }
      ],
      selectedGroupName: 'ALL',
      selectedTabIndex: 0,
    };
  },
  computed: {
    selectedGroup() {
      // Get the group based on the title property in the navigation array. If 'all' is selected, return all notifications.
      const allSources = [
        ...this.notifications,
        ...this.mentions,
      ];
      if (this.selectedGroupName === 'ALL') {
        return allSources;
      }
      return allSources.filter(item => item.category === this.selectedGroupName || item.type === this.selectedGroupName);
    },
  },
  methods: {
    t,
    ...mapActions({
      showNotification: `notifications/${SET_VISIBLE}`,
      hideNotification: `notifications/${CLEAR_VISIBLE}`,
    }),
    closeNotifications() {
      this.hideNotification();
    },
    filterSelected(item) {
      this.$emit('filterSelected', item);
    },
    focus() {
      this.$refs.select.close(); // References the close method on the select component
    },
    keyup(event) {
      if (event.code !== 'Enter') {
        this.$emit('keyup', event.target.value.trim());
      }
    },
    keypress(event) {
      if (event.code === 'Enter') {
        this.$emit('search');
      }
    },
    navClicked(data) {
      const { index, item } = data;
      this.selectedTabIndex = index;
      this.selectedGroupName = item.key;
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications-heading {
  h3 {
    padding: var(--spacing-2);
    font-weight: var(--font-weight-semibold);
  }
  .menu-button {
    position: absolute;
    top: calc(var(--spacing-2) + var(--spacing-half));
    right: var(--spacing-6);
  }
  .close-button {
    position: absolute;
    top: var(--spacing-2);
    right: var(--spacing-1);
  }
}
</style>
