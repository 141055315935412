<template>
  <div :class="`search-container ${mq.current}`">
    <div class="container px-1">
      <div class="search-input">
        <div class="text">
          <input
            id="search-input"
            ref="searchInput"
            type="text"
            :placeholder="placeholder"
            :aria-label="placeholder"
            :disabled="!selectedFilter"
            @keyup="keyup"
            @keypress="keypress"
            @focus="focus"
          >
        </div>
        <div class="filter">
          <SearchSelect
            ref="select"
            tabindex="0"
            :items="filters"
            :selected-value="selectedFilter"
            @on-change="filterSelected"
            @close="closeSelected"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchSelect from '@/shared/components/Form/SearchSelect.vue';

export default {
  name: 'SearchBar',
  components: {
    SearchSelect,
  },
  inject: ['mq'],
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    selectedFilter: {
      type: String,
      default: '',
    },
    filters: {
      type: Array,
      default: () => [],
    },
    focusOnInputAfterSelect: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['filterSelected', 'keyup', 'search', 'closeSelected'],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    filterSelected(item) {
      this.$emit('filterSelected', item);
      if (this.focusOnInputAfterSelect) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      }
    },
    keyup(event) {
      if (event.code !== 'Enter') {
        this.$emit('keyup', event.target.value.trim());
      }
    },
    keypress(event) {
      if (event.code === 'Enter') {
        this.$emit('search');
      }
    },
    focus() {
      this.$refs.select.close(); // References the close method on the select component
    },
    closeSelected() {
      this.$emit('closeSelected');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.search-container {
  position: relative;
  padding: calc(var(--spacing-1) + var(--spacing-half));
  padding-left: 0px;
  padding-right: 0px;
  background: var(--colour-brand-puerto-rico);
  z-index: 2;

  .search-input {
    display: flex;
    border-radius: 100px;
    background: white;
    overflow: hidden;
    width: 100%;

    .text {
      background: url("data:image/svg+xml,%3Csvg width='32px' height='32px' fill='%237360A4' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.437 26.926l2.571 -3.065l14.555 12.213l-2.571 3.065l-14.555 -12.213Z' /%3E%3Cg%3E%3CclipPath id='1'%3E%3Cpath d='M19 6c7.18 0 13 5.82 13 13c0 7.18 -5.82 13 -13 13c-7.18 0 -13 -5.82 -13 -13c0 -7.18 5.82 -13 13 -13Zm0 4c-4.971 0 -9 4.029 -9 9c0 4.971 4.029 9 9 9c4.971 0 9 -4.029 9 -9c0 -4.971 -4.029 -9 -9 -9Z' /%3E%3C/clipPath%3E%3Cg clip-path='url(%231)'%3E%3Crect x='6' y='6' width='26' height='26' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
        no-repeat 8px 4px;
      // Don't be alarmed. This SVG encoded as a data URI is an exception. It allows us to use an SVG as a background image and change its colour without hving to create a new image.

      background-size: 32px;
      display: flex;
      width: 100%;
      align-items: center;

      .svg {
        margin-left: var(--spacing-1);
      }

      input {
        background-color: transparent;
        padding: var(--spacing-1) var(--spacing-1) var(--spacing-1) var(--spacing-5);
        box-shadow: none;
        width: 100%;
        font-weight: normal !important;
        border: none;
      }
    }
  }
  &.xs {
    min-width: 100%;
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    width: 435px;
  }
}
</style>
