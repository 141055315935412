<template>
  <div class="search-bar-details">
    <div class="row">
      <div class="col col-11">
        <h4>
          {{ t('search_type', { type } ) }}
          <span v-if="type !== 'customers' && !isGlobal"> at {{ customerName }}</span>
        </h4>
        <small v-if="type === 'customers'">
          {{
            t('accepted_terms_termlist', { termlist: t('customer_name_account_number_town_postcode') })
          }}
        </small>
        <small v-if="type === 'contact'">
          {{ 
            t('accepted_terms_termlist', { termlist: t('contact_name') }) 
          }}
        </small>
        <small v-if="type === 'prospects'">
          {{
            t('accepted_terms_termlist', { termlist: t('prospect_name_town_postcode') })
          }}
        </small>
        <small v-if="['opportunities', 'interactions'].includes(type)">
          {{
            t('accepted_terms_termlist', { termlist: t('customer_name_prospect_name_or_assignee') })
          }}
        </small>
        <small v-if="(!query || query.length < 3) && type === 'contact'">
          {{ t('search_criteria_3_letters') }}
        </small>
        <div>
          <small
            v-if="!isGlobal && type !== 'customers'"
            class="link clear-both"
            role="button"
            tabindex="0"
            @click="searchAll"
            @keydown.enter="searchAll"
          >
            {{
              t('search_all_type', { type })
            }}
          </small>
        </div>
        <slot :type="type" />
      </div>
      <div class="col col-1">
        <button @click="close">
          <IconBase
            icon-name="close-line"
            icon-color="var(--colour-utility-black)"
            :height="24"
            :width="24"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { tHeader as t } from '@sales-i/utils';
import { IconBase } from '@sales-i/dsv3';

defineProps({
  type: {
    type: String,
    default: '',
  },
  query: {
    type: String,
    default: '',
  },
  data: {
    type: Array,
    default: () => [],
  },
  isGlobal: {
    type: Boolean,
    default: false,
  },
  customerName: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close', 'searchAll']);

const close = () => {
  emit('close');
};

const searchAll = () => {
  emit('searchAll');
};
</script>

<style lang="scss" scoped>
.search-bar-details {
  position: sticky;
  padding: var(--spacing-2);
  background-color: var(--colour-utility-white);
  top: 0;
  background: white;
  z-index: 1;

  small {
    display: block;
    margin-top: calc(var(--spacing-1) / 2);
  }
}
</style>
