<template>
  <div
    v-show="open"
    class="nav-drawer-wrap"
  >
    <div
      class="nav-overlay"
      tabindex="-1"
      aria-hidden="true"
      @click="closeDrawer"
    />
    <nav
      class="nav-drawer"
      :class="{ show: open }"
    >
      <div class="nav-drawer__content">
        <div class="nav-drawer__header">
          <CustomerMode
            :icon-height="69"
            :icon-width="69"
          />

          <IconButton
            class="close-nav-drawer"
            icon-name="close"
            icon-color="iconColor"
            purpose="transparent"
            title="Close"
            :icon-size="48"
            @click="closeDrawer"
          />
        </div>
        
        <div class="nav-drawer__list flow">
          <div
            v-for="item in computedItems"
            :key="item.title"
            class="list-item-group"
            :class="{ active: item.active }"
          >
            <h2>
              {{ item.title }}
            </h2>
            <template v-if="item.sections">
              <div
                v-for="section in item.sections"
                :key="section.title"
              >
                <h3 v-if="section.title">
                  {{ section.title }}
                </h3>
                <ul class="section">
                  <li
                    v-for="link in section.items"
                    :key="link.title"
                    class="list-item"
                    :class="{ active: $route.fullPath.includes(link.path) }"
                    role="link"
                    tabindex="0"
                    :aria-current="currentPage(link)"
                    @click.prevent="linkClicked($event, link)"
                    @keydown.enter="linkClicked($event, link)"
                  >
                    <a
                      class="list-item__title"
                      :href="link.path"
                      tabindex="-1"
                    >
                      {{ link.mobileTitle || link.title }}
                    </a>
                  </li>
                </ul>
              </div>
            </template>
            <template v-else>
              <ul class="section">
                <li
                  class="list-item"
                  role="link"
                  tabindex="0"
                  :aria-current="currentPage(item)"
                  @click.prevent="linkClicked($event, item)"
                  @keydown.enter="linkClicked($event, item)"
                >
                  <a
                    class="list-item__title"
                    :href="item.path"
                    tabindex="-1"
                  >
                    {{ item.mobileTitle || item.title }}
                  </a>
                </li>
              </ul>
            </template>
          </div>
        </div>
        
        <div class="nav-drawer__footer">
          <IconButton
            icon-name="gear"
            :text-color="isSettingsRoute ? 'var(--colour-data-puerto-rico)' : null"
            :icon-color="isSettingsRoute ? 'var(--colour-data-puerto-rico)' : null"
            purpose="transparent"
            :label="t('settings')"
            :title="t('settings')"
            :icon-size="48"
            @click.stop="handleSettingsClick(false)"
          >
            <div
              v-if="showSettingsOptions"
              class="settings-options-background"
              tabindex="0"
              role="button"
              @click.stop="handleSettingsClick(true)"
              @keypress.enter="() => {}"
            />
            <div
              v-if="showSettingsOptions"
              class="settings-options"
            >
              <CustomButton
                purpose="text"
                @click.stop="handleSettingsClick(true)"
              >
                {{ t('global') }}
              </CustomButton>
              <CustomButton
                purpose="text"
                @click.stop="handleMobileSettingsClick"
              >
                {{ t('mobile') }}
              </CustomButton>
              <div class="bubble-point" />
            </div>
          </IconButton>
          <IconButton
            v-if="platform.data !== 'mobile'"
            icon-name="exit"
            icon-color="iconColor"
            purpose="transparent"
            :label="t('sign_out')"
            :title="t('sign_out')"
            :icon-size="48"
            @click.stop="handleSignoutClick"
          />
        </div>
      </div>
      <div class="nav-drawer__border" />
    </nav>
  </div>
</template>

<script>
import { IconButton, CustomButton } from '@sales-i/dsv3';
import CustomerMode from '@/shared/components/CustomerMode/CustomerMode.vue';
import { SET_VISIBLE, LOG_OUT } from '@/shared/store/actionType';
import { mapState, mapActions } from 'vuex';
import {
  showSearchBar,
  setSearchBarType,
  tHeader as t
} from '@sales-i/utils';

import {
  baseUrl as intelligenceBaseUrl,
  enquiryScope,
  insightsScope,
  alertsScope
} from '@/intelligence/router/urlBits';
import {
  baseUrl as crmBaseUrl,
} from '@/crm/router/urlBits';
import { navigateToUrl } from 'single-spa';
import { baseUrl as adminBase } from '@/admin/router/urlBits';

export default {
  name: 'NavigationDrawer',
  components: {
    CustomerMode,
    IconButton,
    CustomButton
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['closeDrawer', 'navDrawState', 'accountMenuClicked'],
  data() {
    return {
      iconColor: 'var(--colour-brand-deluge)',
      selectedPath: [],
      showSettingsOptions: false,
    };
  },
  computed: {
    ...mapState({
      platform: state => state.platform,
    }),
    open: {
      get() {
        return this.drawer;
      },
      set() {},
    },
    computedItems() {
      const navItems = this.platform.data === 'mobile' ? {
        0: {
          path: '/my-sales-ai',
        },
        1: {
          path: `${intelligenceBaseUrl}/${enquiryScope}`,
        },
        2: {
          path: `${intelligenceBaseUrl}/${insightsScope}`,
        },
        3: {
          path: `${intelligenceBaseUrl}/${alertsScope}`,
        },
        4: {
          path: crmBaseUrl,
        },
      } : {
        0: {
          path: `${intelligenceBaseUrl}/${enquiryScope}`,
        },
        1: {
          path: `${intelligenceBaseUrl}/${insightsScope}`,
        },
        2: {
          path: `${intelligenceBaseUrl}/${alertsScope}`,
        },
        3: {
          path: crmBaseUrl,
        },
      };
      const route = this.$route;
      return this.items.map((navItem, i) => ({
        ...navItem,
        active: route.path.includes(navItems[i].path),
      }));
    },
    isSettingsRoute() {
      return this.$route.path.includes('admin');
    },
  },
  watch: {
    $route(to) {
      this.selectedPath = [];
      this.addCurrentPath(to.path);
    },
  },
  mounted() {
    const currentPath = this.$route ? this.$route.path : '';
    this.addCurrentPath(currentPath);
    window.addEventListener('keydown', this.keyDownHandler);
  },
  unmounted() {
    window.removeEventListener('keydown', this.keyDownHandler);
  },
  methods: {
    t,
    ...mapActions({
      setVisible: `reportsModal/${SET_VISIBLE}`,
      logOutUser: `userDetails/${LOG_OUT}`,
    }),
    closeDrawer() {
      this.showSettingsOptions = false;
      this.$emit('closeDrawer');
    },
    input(open) {
      this.$emit('navDrawState', open);
    },
    keyDownHandler(event) {
      if (event.code === 'Escape') {
        this.$emit('closeDrawer');
      }
    },
    linkClicked(event, link) {
      event.stopPropagation();
      this.selectedIndex = undefined;

      let entity = [];
      const linkTitle = link.title.slice(0, -1).toLowerCase();

      if (entity.includes(link.title)) {
        showSearchBar();
        setSearchBarType(linkTitle);
      } else if (link.title === t('template_reports')) {
        this.setVisible();
      } else if (link.title === t('my_reps')) {
        try {
          window.webkit.messageHandlers.myRepsHandler.postMessage('open_reps');
        } catch (err) {
          // onError
        }
      } else if (this.$route.path !== link.path) {
        navigateToUrl(link.path);
      }
      this.$emit('closeDrawer');
    },
    addCurrentPath(currentPath) {
      this.items.forEach(link => {
        link.sections && link.sections.forEach(e => {
          if (currentPath === e.path) {
            this.selectedPath.push(link.title);
            this.selectedPath.push(e.path);
          }
        });
      });
    },
    currentPage(link) {
      if (this.selectedPath.includes(link.path)) {
        return 'page';
      }
    },
    handleSettingsClick(menuShowing) {
      if (this.platform.data === 'mobile' && !menuShowing) {
        this.showSettingsOptions = true;
      } else {
        navigateToUrl(adminBase);
        this.closeDrawer();
      }
    },
    handleMobileSettingsClick() {
      this.closeDrawer();
      this.$emit('accountMenuClicked');
    },
    async handleSignoutClick() {
      await this.logOutUser();
      navigateToUrl('/');
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  cursor: pointer;
}

.nav-drawer {
  z-index: 1000;
  position: fixed;
  animation-duration: 1s;
  height: 100%;
  top: 0px;
  background-color: var(--colour-panel-action);
  overflow-y: auto;
  width: 100vw;

  &.show {
    animation-name: navslide;
    animation-duration: 0.1s;
  }

  /* The animation code */
  @keyframes navslide {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
  }
}

.nav-drawer__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

  .nav-drawer__header {
    display: flex;
    gap: var(--spacing-1);
    justify-content: flex-end;
    margin: var(--spacing-2) var(--spacing-2) 0;
  }

  .nav-drawer__list {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
  }

  .nav-drawer__footer {
    background-color: var(--colour-utility-white);
    font-weight: var(--font-weight-semibold);
    margin-block-start: var(--spacing-2);
    padding: var(--spacing-2);
    display: flex;
    justify-content: space-evenly;

    :deep(.icon-button) {
      font-size: var(--font-size-5);
    }
  }


h2 {
  font-size: var(--font-size-2);
  padding: 0 var(--spacing-2);
}

h3 {
  font-size: var(--font-size-4);
  padding: var(--spacing-2) var(--spacing-2) 0 var(--spacing-2);
}

.list-item-group {
  --flow-space: var(--spacing-3);
  &.active h2 {
    color: var(--colour-data-puerto-rico);
  }
}

.list-item {
  display: flex;
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: var(--colour-panel-g-8);
  }

  &[aria-current] {
    background-color: var(--colour-panel-g-16);
  }

  &.active a {
    color: var(--colour-data-puerto-rico);
  }
}

.list-item__title {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-semibold);
  line-height: 1.4;
  padding: var(--spacing-1) var(--spacing-2);
}

.settings-options {
  display: flex;
  position: absolute;
  top: -36px;
  padding: var(--spacing-1);
  gap: var(--spacing-2);
  border-radius: var(--border-radius-1);
  background-color: var(--colour-panel-g-8);
}

.settings-options-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--colour-utility-black);
  opacity: 25%;
}

.bubble-point {
  position: absolute;
  height: 0;
  width: 0;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--colour-panel-g-8);
}

// Overrides
:deep(.svg-container) {
  width: 40px;
  height: 40px;
}

:deep(.icon-button > div) {
  height: auto;
  width: auto;
  margin-left: inherit;
}
</style>