<template>
  <div
    v-if="items && items.length"
    :class="`tabs ${mq}`"
    :style="cssVars"
  >
    <div
      role="tablist"
      class="tabs-bar"
    >
      <div class="slide-group__wrapper">
        <ul
          ref="tabscontent"
          class="slide-group__content"
          role="tablist"
        >
          <li
            v-for="(item, index) in items"
            :id="item.title"
            :key="index"
            :ref="`tab-${index}`"
            :aria-selected="index === selected ? true : null"
            role="tab"
            class="tab"
            tabindex="-1"
            :class="[{ 'tab--active': index === selected, disabled: item.disabled }, selectedGroupName]"
            @click.stop="navClicked(index, item)"
            @keyup="handleKeyPress(index, item)"
          >
            <slot
              :item="item"
              :index="index"
              :icon-color="iconColor"
              :icon-siz="iconSize"
            >
              <IconBase
                v-if="item.showIcon"
                :icon-name="item.icon"
                :icon-color="iconColor"
                :width="iconSize"
                :height="iconSize"
                class="mr-1"
                :disabled="item.disabled"
              />
              {{ t(item.title) }}
            </slot>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { tHeader as t } from '@sales-i/utils';
import { IconBase } from '@sales-i/dsv3';
import breakpoints from '@/shared/utils/breakpoints';

const FONT_SIZE = 'var(--font-size-3)';
const TAB_PADDING = 'var(--spacing-1) var(--spacing-3)';
const TAB_INDENT = '0px';

export default {
  name: 'InlineTabs',
  components: { IconBase },
  inject: ['mq'],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedIndex: {
      type: Number,
      default: 0,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    twoway: {
      type: Boolean,
      default: true,
    },
    iconColor: {
      type: String,
      default: 'var(--colour-utility-base)',
    },
    iconSize: {
      type: Number,
      default: 24,
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
    selectedGroupName: {
      type: String,
      default: '',
    },
  },
  emits: ['navClicked'],
  data() {
    return {
      breakpoints,
      selected: this.selectedIndex,
      scrollAmount: 0,
      elementToScroll: 'tabscontent',
    };
  },
  computed: {
    cssVars() {
      return {
        '--tab-font-size': this.styles.fontSize ? this.styles.fontSize : FONT_SIZE,
        '--tab-padding': this.styles.tabPadding ? this.styles.tabPadding : TAB_PADDING,
        '--tab-indent': this.styles.indent ? this.styles.indent : TAB_INDENT,
      };
    },
  },
  watch: {
    selectedIndex(value) {
      if (this.twoway) this.selected = value;
    },
  },
  unmounted() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize);

    // Set the tabindex of the first tab to 0 if there is any data
    if (this.items.length > 0) {
      this.$refs[`tab-${this.selected}`][0].tabIndex = 0;
    }
  },
  methods: {
    t,
    onWindowResize() {
      this.scrollAmount = 0;
    },
    navClicked(index, item) {
      if (item.disabled) return;
      this.$emit('navClicked', {
        index,
        item,
      });
      this.selected = index;

      // Set the tabindex of the selected tab to 0
      this.$refs[`tab-${this.selected}`][0].tabIndex = 0;

      // Remove tabindex from all other tabs
      this.items.forEach((item, i) => {
        if (i !== this.selected) {
          this.$refs[`tab-${i}`][0].tabIndex = -1;
        }
      });
    },

    handleKeyPress() {
      const tabs = this.items;
      // Create an array containing LEFT and RIGHT arrow keys
      const arrowKeys = [37, 39];

      // Check if the arrowKeys array contains the key pressed
      if (arrowKeys.includes(event.keyCode)) {
        // Pressing the right arrow key selects the next tab (if the user is not on the last tab)
        if (event.keyCode === 39) {
          if (this.selected < tabs.length - 1) {
            this.selected = this.selected + 1;
          }
        }

        // Pressing the left arrow key selects the next tab (if the user is not on the first tab)
        if (event.keyCode === 37) {
          if (this.selected > 0) {
            this.selected = this.selected - 1;
          }
        }

        // Set the tabindex of the selected tab to 0
        this.$refs[`tab-${this.selected}`][0].tabIndex = 0;

        // Ensure the selected tab has a focus state
        this.$refs[`tab-${this.selected}`][0].focus();

        // Set the tabindex of the remaining tabs to -1
        this.items.forEach((item, i) => {
          if (i !== this.selected) {
            this.$refs[`tab-${i}`][0].tabIndex = -1;
          }
        });
      }

      this.$emit('navClicked', {
        index: this.selected,
        item: this.items[this.selected],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.slide-group__wrapper {
  position: relative;
  contain: content;
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

.slide-group__content {
  display: flex;
  flex: 1 0 auto;
  margin-block: 1px;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;
  flex-basis: 0;

  background-repeat: no-repeat;
  background-size: 20px 100%, 20px 100%, 20px 100%, 20px 100%;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll;

  overflow: auto;
  transition: all 0.2s;
  position: relative;
}

.tabs {
  border-bottom: 1px solid var(--colour-utility-black);
  margin-bottom: 0;
}

.tab {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  max-width: 360px;
  outline: none;
  text-decoration: none;
  user-select: none;
  border-radius: 0;
  color: var(--colour-utility-base);
  font-size: var(--tab-font-size);
  font-weight: var(--font-weight-medium);
  padding: var(--tab-padding);
  line-height: normal;

  &:hover,
  &:focus {
    background-color: var(--colour-panel-g-4);
    transition: 0.5s ease;
  }

  &:first-child {
    margin-left: var(--tab-indent);
  }
}

.tab--active {
  // Set the default accent colour for the tab's text/border custom property
  --tab-accent-colour: var(--colour-utility-black);

  color: var(--tab-accent-colour);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
    background-color: var(--tab-accent-colour);
    border-radius: 2px 2px 0 0;
  }
}

.disabled {
  color: var(--colour-panel-g-24);
  pointer-events: none;
}
</style>
