<template>
  <CustomModal
    :show-modal="tagListModal && permissions.read"
    :min-width="300"
    :max-width="400"
    position="center"
    size="small"
    @close-modal="closeModal"
  >
    <div class="px-2 pb-3">
      <div v-if="loaded">
        <p class="pr-5">
          <span v-if="noTags && !loading">{{ t('you_have_not_added_any_tags') }}</span>
        </p>
      </div>
    </div>

    <!-- Display the list of current tags -->
    <!-- and Add a tag -->
    <div class="pb-3 px-2">
      <TagInput
        :entity="props.entity"
        :entity-id="props.id"
        :permissions="permissions"
        @input="setTags"
      />
    </div>

    <template #footer>
      <div class="text-right">
        <CustomButton
          v-if="!submitting && canSave"
          type="submit"
          small
          @click="saveTags"
        >
          {{ t('save') }}
        </CustomButton>
        <BufferImage
          v-if="submitting"
          color="var(--colour-utility-black)"
          float="center"
        />
      </div>
    </template>
  </CustomModal>
</template>

<script setup>
import { tHeader as t } from '@sales-i/utils';
import { computed, ref, watch } from 'vue';
import { BufferImage, CustomModal, CustomButton } from '@sales-i/dsv3';
import TagInput from './TagInput.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  POST_BY_ENTITY,
  CLEAR_BY_ENTITY,
  CLEAR_VISIBLE,
  SET_CONFIRMATION_MODAL,
  CLEAR_CONFIRMATION_MODAL,
  DELETE_BY_ENTITY,
} from '@/shared/store/actionType';
import usePermissions from '@/shared/composables/usePermissions';
import { tags as tagsPerm } from '@/shared/store/data/policies';

const { getPermissions } = usePermissions();
const permissions = getPermissions(tagsPerm);
const canSave = permissions.delete || permissions.create;

const props = defineProps({
  id: {
    type: [String, Number],
    default: '',
  },
  entity: {
    type: String,
    default: '',
  },
});

const store = useStore();
const vroute = useRoute();

const tagListModal = ref(true);
const isInputting = ref(false);
const addTags = ref([]);
const deleteTags = ref([]);
const submitting = ref(false);

const tags = computed(() => store.state.tags);

const noTags = computed(() => {
  let tagsMinusDelete = tags.value.entity.data.filter(tag => !deleteTags.value.find(deleteTag => deleteTag.tag === tag.tag));
  let tagsAdded = [...addTags.value];
  if (isInputting.value) {
    tagsAdded.pop();
  }
  return [...tagsAdded, ...tagsMinusDelete].length === 0;
});

const loading = computed(() => tags.value.entity.loading);
const loaded = computed(() => tags.value.entity.loaded);

watch(
  () => vroute.path,
  () => clearVisible()
);

const postByEntity = params => store.dispatch(`tags/${POST_BY_ENTITY}`, params);
const deleteByEntity = params => store.dispatch(`tags/${DELETE_BY_ENTITY}`, params);
const clearByEntity = () => store.dispatch(`tags/${CLEAR_BY_ENTITY}`);
const clearVisible = () => store.dispatch(`tags/${CLEAR_VISIBLE}`);
const setConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);
const clearConfirmationModal = () => store.dispatch(`confirmationModal/${CLEAR_CONFIRMATION_MODAL}`);

function setTags(value) {
  isInputting.value = value.isInputting;
  addTags.value = value.tagsToAdd;
  deleteTags.value = value.tagsToDelete;
}

async function saveTags() {
  submitting.value = true;
  if (addTags.value.length > 0) {
    await postByEntity({
      id: props.id,
      tags: addTags.value,
      entity: props.entity,
    });
  }
  if (deleteTags.value.length > 0) {
    await deleteByEntity({
      id: props.id,
      tags: deleteTags.value,
      entity: props.entity,
    });
  }
  submitting.value = false;
  addTags.value = [];
  deleteTags.value = [];
  closeModal();
  return true;
}

function closeModal() {
  if (addTags.value.length > 0 || deleteTags.value.length > 0) {
    setConfirmationModal({
      message: t('you_havent_saved_your_changes'),
      cancelLabel: t('Don’t Save', 'dont_save'),
      saveLabel: t('save'),
      closeOnUpdate: true,
      closeOnCancel: false,
      updateFunction: () => saveTags(),
      closeFunction: () => true,
      cancelFunction: () => {
        tagListModal.value = false;
        clearVisible();
        clearByEntity();
        clearConfirmationModal();
      },
    });
    return;
  }
  clearVisible();
  clearByEntity();
}
</script>
