<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="search-bar-result">
    <BufferImage
      v-if="loading"
      color="black"
      float="center"
    />
    <div
      v-if="uniqueData.length > 0"
      role="list"
      class="list list-card all"
    >
      <SearchResultCard
        v-for="item in uniqueData"
        :key="item.id"
        :item="item"
        :type="type"
        @item-clicked="itemClicked"
      />
    </div>
    <div
      v-if="query === ''"
      role="list"
      class="list list-card all"
    >
      <strong>{{ t('recently_viewed') }}</strong>
      <SearchResultCard
        v-for="item in recentlyViewed"
        :key="item.id"
        :item="item"
        :type="type"
        @item-clicked="itemClicked"
      />
    </div>
    <div v-if="loaded && data.length === 0">
      {{ t('results_not_found') }}
    </div>
    <CustomButton
      v-if="showMoreButton"
      purpose="neutral"
      small
      :disabled="loading"
      class="more-btn"
      @on-click="searchMore"
    >
      {{ !loading ? t('more') : t('loading') }}
    </CustomButton>
  </div>
</template>

<script>
import { CustomButton, BufferImage } from '@sales-i/dsv3';
import { address, currency, dates, pluralise, tHeader as t } from '@sales-i/utils';
import { highlight } from '@/shared/utils/highlight';
import { mapState } from 'vuex';
import { baseUrl, contactsSection, opportunitiesArea, prospectsArea } from '@/crm/router/urlBits';
import { navigateToUrl } from 'single-spa';
import SearchResultCard from './SearchResultCard.vue';

export default {
  name: 'SearchBarResultList',
  components: {
    BufferImage,
    CustomButton,
    SearchResultCard,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    showMoreButton: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    loaded: {
      type: Boolean,
    },
    type: {
      type: String,
      default: '',
    },
    query: {
      type: String,
      default: ''
    },
    recentlyViewed: {
      type: Array,
      default: () => []
    }
  },
  emits: ['itemClicked', 'deleteItem', 'searchMore'],
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      journey: state => state.journey.data,
      currencyName: state => state.userDetails.currencyName
    }),
    uniqueData() {
      const uniqueAccountNumbers = new Set();
      return this.data.filter(item => {
        if (!uniqueAccountNumbers.has(item.id)) {
          uniqueAccountNumbers.add(item.id);
          return true;
        }
        return false;
      });
    }
  },
  methods: {
    t,
    formatDate: dates.format,
    highlight,
    itemClicked(item) {
      this.$emit('itemClicked', item);
    },
    edit(link) {
      let { item } = link;
      if (this.type === 'contact') {
        navigateToUrl(
          `${baseUrl}/${pluralise.string(item.relationship.record_type)}/${item.relationship.id}/${contactsSection}/${item.id}/edit`
        );
      } else if (this.type === 'prospect') {
        navigateToUrl(`${baseUrl}/${prospectsArea}/${item.id}/edit`);
      } else if (this.type === 'opportunity') {
        navigateToUrl(`${baseUrl}/${opportunitiesArea}/${item.id}/edit`);
      }
    },
    deleteItem(link) {
      this.$emit('deleteItem', link.item);
    },
    handleMapLocation({ item }) {
      address.showOnMap(item.address);
    },
    getChips(item) {
      if (this.type === 'opportunity') {
        return [
          {
            value: item.value,
            prefix: currency.symbol(this.currencyName),
            abbr: true,
            green: 150000,
            amber: 75000,
          },
          {
            value: item.probability,
            suffix: '%',
            green: 67,
            amber: 33,
          },
        ];
      }
      // if (this.type === 'contact') {
      //   return [{
      //     value: item.percent_complete,
      //     suffix: '%',
      //     green: 67,
      //     amber: 33,
      //   },];
      // }
      return [];
    },
    getCardLinks(item) {
      const openObj = {
        text: this.t('open'),
        item: item,
        emit: 'open',
      };
      const editObj = {
        text: this.t('edit'),
        item: item,
        emit: 'edit',
      };
      const deleteObj = {
        text: this.t('delete'),
        item: item,
        emit: 'delete',
      };
      if (this.journey.entity && this.journey.action) {
        return [
          {
            text: this.t('select'),
            item: item,
            emit: 'open',
          },
        ];
      }
      if (this.type === 'customer') {
        return [
          address.isValid(item.address)
            ? {
              text: this.t('map_location'),
              item: item,
              emit: 'map-location',
            }
            : {},
          openObj,
        ];
      }
      if (this.type === 'contact') {
        return [
          openObj,
          editObj,
          {
            text: item.mobile_phone ? this.t('call') : '',
            type: 'a',
            href: `tel:${item.mobile_phone}`,
          },
          {
            text: item.email ? this.t('email') : '',
            type: 'a',
            href: `mailto:${item.email}`,
          },
        ];
      }
      if (this.type === 'prospect') {
        return [openObj, editObj];
      }
      if (this.type === 'opportunity') {
        return item.status === 'open' ? [openObj, editObj, deleteObj] : [openObj, deleteObj];
      }
      if (this.type === 'interaction') {
        return [openObj, deleteObj];
      }
      return [];
    },
    searchMore() {
      this.$emit('searchMore');
    },
  }
};
</script>

<style lang="scss" scoped>
.search-bar-result {
  padding: var(--spacing-2);
  overflow: auto;
  min-height: calc(100% - 96px);
  .list-card.list {
    grid-template-columns: repeat(auto-fit, minmax(100%, 100%));
  }
}
.more-btn {
  display: block;
  margin: var(--spacing-1) auto;
}

// move to separate component I guess?
.recent-emails-heading {
  font-weight: var(--font-weight-semibold);
  text-align: left;
}
.mail-item {
  display: flex;
  padding: var(--spacing-1);
  padding-left: 0;
  gap: var(--spacing-1);
}

.initials-tag {
  font-size: var(--font-size-small);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: lightblue;
  color: darkblue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subject-tag {
  font-size: var(--font-size-small);
  display: flex;
  align-items: center;
  font-style: italic;
}
</style>
